<script setup>
const backend = useBackend();

const refSlideControl = ref();
const refSlideNavigation = ref();

const blocks = ref([]);

const { data } = await useAsyncData(async () => {
  const result = await backend.getModelVideos();
  if (!result?.data || !result.data.length) return [];
  blocks.value = result.data;
});

const dontShow = ref([]);

const hideWhenDontExist = (index) => {
  if (!dontShow.value.includes(index)) {
    dontShow.value.push(index);
  }
};

const visibleSlidesCount = computed(() => {
  return blocks.value.filter((_, i) => !dontShow.value.includes(i)).length;
});

const updateSlideNumber = (slideNumber) => {
  refSlideNavigation.value.setSlide(slideNumber);
};

const scrollToSlide = (slide) => {
  refSlideControl.value.scrollToSlide(slide);
};
</script>

<template>
	<div v-if="blocks.length && visibleSlidesCount">
		<Container gray>
			<SubContentHeader>Znajdź inspirację w naszych reelsach</SubContentHeader>
			<SlideControl
        ref="refSlideControl"
        class="-mt-4 !pr-0"
        @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
      >
				<template v-for="(block, index) in blocks" :key="index">
					<SlideItem v-if="!dontShow.includes(index)">
						<CarVideoCard
              @dont-exist="hideWhenDontExist"
              :video="block"
              :isFirst="index === 0"
              :keyIndex="index"
            />
					</SlideItem>
				</template>
			</SlideControl>
			<SlideNavigation
				ref="refSlideNavigation"
				class="-mt-4"
				:items="visibleSlidesCount"
				@changed="(slide) => scrollToSlide(slide)"
			/>
		</Container>
	</div>
</template>