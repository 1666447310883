<script setup>
import { useNuxtApp } from "#app";
const backendStore = useBackendStore();
const { isEmbed } = backendStore;
const carCount = backendStore.carCount.value;

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

const {$onEvent} = useNuxtApp();
const showContactPopup = ref(false);
const contactPopupTitle = ref("Zostaw kontakt!");

const {data: cars} = await useAsyncData(() => new Promise(async resolve => {
  const models = await useBackend().getModels({promoted: 1});
  resolve(models)
}))

const {data: popularCars} = await useAsyncData(() => new Promise(async resolve => {
  const models = await useBackend().getPopularModels();
  resolve({ data: models.data })
}))

const shuffledCars = ref([])

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

onMounted(() => {
  if (popularCars.value?.data) {
    shuffledCars.value = shuffleArray([...popularCars.value.data])
  }
})

$onEvent('specialBanner:click', () => {
  showContactPopup.value = true;
})
</script>

<template>
  <Container no-paddings>
    <AppTop />
    <div>
      <LayoutColumns useEmbedState>
        <LayoutMainCarsColumn :full="isEmbed" class="pb-4">
          <div :class="!isEmbed ? 'md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6' : 'col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-4'">
            <MainContentHeader>Leasing samochodu w VEHIS - najpopularniejsze modele</MainContentHeader>
          </div>
          <template v-for="(car, index) in shuffledCars.slice(0, 6)">
            <CarTile :car="car" :bg="{ img: car.background_image }" />
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn v-if="!isEmbed" class="lg:mt-20 pb-4 !bg-transparent">
          <FormContact />
          <div class="h-4"></div>
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>

  <CarDayOffers v-if="!isEmbed" />

  <CarVideoReel />

  <BannerBenefits v-if="!isEmbed" />

  <CarCollections v-if="!isEmbed" />

  <Container v-if="!isEmbed">
    <SeoMoreAboutModels :cars="cars.data" title="Oferta tygodnia:" />
  </Container>

  <Container with-border-top v-if="!isEmbed">
    <BlockBestFeaturesCompared />
  </Container>

  <Container class="pt-8 pb-4" no-paddings with-border-top v-if="!isEmbed">
    <BlockHowTo :steps="[`Wybierz samochód z ponad <b>${carCount}</b> dostępnych ofert.`,'<b>Wspólnie skalkulujemy</b> ofertę<br/>finansowania i ubezpieczenia.','Finansowanie przyznamy<br/><b>w 15 minut</b> od złożenia wniosku.','Samochód odbierzesz nawet<br/><b>w 24h</b> od podpisania umowy.']" />
  </Container>

  <Container class="pt-8 pb-12" no-paddings with-border-top v-if="!isEmbed">
    <SeoHomePage />
  </Container>

  <Container class="pt-10 pb-6" no-paddings gray v-if="!isEmbed">
    <BlockFeaturesComparedText />
  </Container>

  <Container v-if="!isEmbed">
    <FormContact title="W każdym momencie możesz skorzystać z&nbsp;pomocy naszych Doradców" standalone />
  </Container>

  <Container with-border-top v-if="!isEmbed">
    <SeoBrandsCloud />
  </Container>

  <Teleport to="body">
    <PopupModal :openPopup="showContactPopup" @update:openPopup="showContactPopup = false">
      <FormContact :title="contactPopupTitle" standalone no-container analytics-form-type="home-baner-popup" />
    </PopupModal>
  </Teleport>
</template>
