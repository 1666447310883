<script setup>
import Player from '@vimeo/player'
const analytics = useAnalytics()

const props = defineProps({
  video: {
    type: Object,
    default: () => ({ brand: '', model: '', video: '' })
  },
  isFirst: {
	type: Boolean,
	default: false
  },
  keyIndex: Number
})

const vimeoPlayer = ref(null)
const isMobile = ref(false)
const videoContainer = ref(null)
const isInView = ref(false)
const showPlayBtn = ref(true)
const isPlaying = ref(false)
const isMuted = ref(true)
const hasStopped = ref(false)
const videoExist = ref(true)
const emits = defineEmits(['dontExist'])
let player = null
let observer

const checkPageWidth = () => {
	isMobile.value = window.innerWidth < 688
}

const getVimeoVideoId = () => {
	if (!props.video.video) return ''
	const match = props.video.video.match(/(?:vimeo\.com\/|video\/)(\d+)/)
	return match ? match[1] : ''
}

const setupVimeoPlayer = () => {
  const videoId = getVimeoVideoId();
  if (!videoId || !vimeoPlayer.value || hasStopped.value) return

	const desktopFunctionality = props.isFirst && isMobile.value === false
	const videoStatus = isInView.value && desktopFunctionality

  if (player) {
    if (videoStatus) {
      player.ready().then(() => {
        player.play()
      })
    }
    return;
  }

  player = new Player(vimeoPlayer.value, {
    id: videoId,
    controls: false,
    autoplay: isInView.value,
    loop: true,
    muted: true,
  })

  player.on('pause', () => {
    showPlayBtn.value = true
		isPlaying.value = false
		hasStopped.value = true
  })

  player.on('play', () => {
    showPlayBtn.value = false
		isPlaying.value = true
  })

  player.getVideoTitle()
    .then(() => {
      videoExist.value = true;
    })
    .catch(() => {
      emits('dontExist', props.keyIndex)
      videoExist.value = false;
    });

  if (videoStatus && !hasStopped.value) {
    player.ready().then(() => {
      player.play()
    })
  }
}

const togglePlayPause = () => {
  if (isPlaying.value) {
    player?.pause()
  } else {
    player?.play()
    analytics.analyticsLayer({
      'event': 'video_play',
      'brand': props.video?.brand,
      'model': props.video?.model,
      'movie': getVimeoVideoId()
    })
  }
}

const toggleMute = () => {
	if (player) {
		isMuted.value = !isMuted.value
    player.setMuted(isMuted.value)
	}
}

onMounted(() => {
	checkPageWidth()
	window.addEventListener("resize", checkPageWidth)

	// Play video only when this component is in view
  observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			isInView.value = entry.isIntersecting
			if (isInView.value) {
				setupVimeoPlayer()
			} else {
        player?.pause()
      }
		})
  }, { threshold: 0.5 })

  if (videoContainer.value) {
    observer.observe(videoContainer.value)
		setupVimeoPlayer()
  }
})

onUnmounted(() => {
  if (observer) observer.disconnect()
	window.removeEventListener("resize", checkPageWidth)
	player?.destroy()
})
</script>

<template>
	<div
    v-if="videoExist"
		ref="videoContainer"
		class="flex items-start flex-col group relative max-w-[300px] min-w-[300px] h-[450px] rounded-lg select-none">
			<IconMute v-if="isMuted" @click.stop="toggleMute" class="w-7 h-7 z-20 absolute right-16 mt-3 text-white" />
			<IconUnmute v-else @click.stop="toggleMute" class="w-7 h-7 z-20 absolute right-16 mt-3 text-white" />
		<div class="h-full max-w-[260px] min-w-[260px] bg-gray-200 rounded-r-lg flex relative">
			<div @click="togglePlayPause" class="w-full h-full">
				<div class="absolute opacity-80 z-10 hover:cursor-pointer w-full h-full flex items-center justify-center">
					<IconPlayVideo v-show="showPlayBtn" class="w-20 h-20 text-white" />
				</div>
				<div ref="vimeoPlayer" class="player-container rounded-lg overflow-hidden" data-vimeo-height="440" data-vimeo-width="250" @click="playVideo"></div>
			</div>
			<div class="mb-2 ml-2 flex flex-col justify-end">
				<NuxtLink
          :to="`/wybierz/${video?.brand}/${video?.model}`"
          class="m-auto mt-4 mb-2 cursor-pointer"
          @click="analytics.analyticsLayer({
            'event': 'video_basket',
            'brand': video?.brand,
            'model': video?.model,
            'link_url': `/wybierz/${video?.brand}/${video?.model}`,
            'movie': getVimeoVideoId()
          })"
        >
					<IconShoppingCart />
				</NuxtLink>
				<NuxtLink
          :to="`/samochody/${video?.brand}/${video?.model}`"
          class="m-auto mt-4 mb-2 cursor-pointer"
          @click="analytics.analyticsLayer({
            'event': 'video_info',
            'brand': video?.brand,
            'model': video?.model,
            'link_url': `/samochody/${video?.brand}/${video?.model}`,
            'movie': getVimeoVideoId()
          })"
        >
					<IconInfo />
				</NuxtLink>
				<!-- <div class="m-auto mt-4 mb-2 cursor-pointer" @click="shareLink">
					<IconShare />
				</div> -->
			</div>
		</div>
	</div>
</template>

<style scoped>
::v-deep(.player-container iframe) {
  border-radius: 1rem !important;
}
</style>
