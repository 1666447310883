<script setup>

const backend = useBackend();
const { data: collections } = await useAsyncData(async () => {
  const collections = await backend.getCollections();
  return collections.data || [];
});


</script>

<template>
  <template v-for="(collection,index) in collections">
    <Container :gray="!(index % 2)">
      <CarCollection :title="collection.title"
                     :search="collection.search"
                     :href="collection.custom_url ?? collection.slug"
                     :isCustomUrl="!!collection.custom_url"
                     limit="12"
      />
    </Container>
  </template>
</template>
